import axios from "axios";
const BASE_API_URL =
    process.env.NODE_ENV === "development"
        ? "http://127.0.0.1:8000"
        : "https://testbs.synvie.de/send";

export const submitContactViaPhoneFormAPI = async (data) => {
    const url = data.questionnaire
        ? `${BASE_API_URL}/contact/questionnaire/phone/`
        : `${BASE_API_URL}/contact/form/phone/`;
    const res = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
    });
    window.dataLayer.push({
        event: "interactionEvent",
        eventAction: "form event | submission",
        eventCategory: "schmerz-spezialisten.de | all pages",
        eventLabel: "Service form",
    });
    return res;
};

export const submitContactViaEmailFormAPI = async (data) => {
    const url = data.questionnaire
        ? `${BASE_API_URL}/contact/questionnaire/email/`
        : `${BASE_API_URL}/contact/form/email/`;
    const res = await axios.post(url, data, {
        headers: { "Content-Type": "application/json" },
    });
    window.dataLayer.push({
        event: "interactionEvent",
        eventAction: "form event | submission",
        eventCategory: "schmerz-spezialisten.de | all pages",
        eventLabel: "Service form",
    });
    return res;
};

export const getOccupiedTimeSlotsAPI = async ({
    selectedDate,
    selectedMonth,
    selectedYear,
}) => {
    const dateToCheck = new Date(selectedYear, selectedMonth, selectedDate);
    const { data } = await axios.post(
        `${BASE_API_URL}/contact/occupied-slots/`,
        {
            date: {
                year: selectedYear,
                month: selectedMonth + 1,
                date: selectedDate,
            },
        },
        {
            headers: { "Content-Type": "application/json" },
        },
    );
    return data;
};

export const getOccupiedDaysAPI = async () => {
    const { data } = await axios.get(`${BASE_API_URL}/contact/occupied-days`);
    return data;
};
