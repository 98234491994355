import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "./../assets/icons/Phone.svg";
import HeftC from "./../assets/icons/ablation.png";
import HeftA from "./../assets/icons/rückenmark.png";
import HeftB from "./../assets/icons/rückenmark_test.png";
import styles from "./Order.module.css";

export default function Order() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        choiceA: false,
        choiceB: false,
        choiceC: false,
        street: "",
        location: "",
        name: "",
        orderEmail: "",
        phone: "",
        newsletter: false,
        agreeTerms: false,
    });

    const handleChange = (e) => {
        if (e.target.name === "choiceA") {
            setInfo((prevInfo) => ({ ...prevInfo, choiceA: !info.choiceA }));
        }

        if (e.target.name === "choiceB") {
            setInfo((prevInfo) => ({ ...prevInfo, choiceB: !info.choiceB }));
        }

        if (e.target.name === "choiceC") {
            setInfo((prevInfo) => ({ ...prevInfo, choiceC: !info.choiceC }));
        }
        if (e.target.name === "name") {
            setInfo((prevInfo) => ({ ...prevInfo, name: e.target.value }));
        }

        if (e.target.name === "street") {
            setInfo((prevInfo) => ({ ...prevInfo, street: e.target.value }));
        }
        if (e.target.name === "location") {
            setInfo((prevInfo) => ({ ...prevInfo, location: e.target.value }));
        }
        if (e.target.name === "orderEmail") {
            setInfo((prevInfo) => ({
                ...prevInfo,
                orderEmail: e.target.value,
            }));
        }
        if (e.target.name === "phone") {
            const phoneInput = e.target.value;

            if (!isNaN(parseInt(phoneInput.slice(-1)))) {
                setInfo({ ...info, phone: phoneInput });
            } else if (!phoneInput.length) {
                setInfo({ ...info, phone: "" });
            }

            if (info.phone.includes("+49")) {
                setInfo({ ...info, phone: phoneInput.replace("+49", "0") });
            }
        }
        if (e.target.name === "newsletter") {
            setInfo((prevInfo) => ({
                ...prevInfo,
                newsletter: !prevInfo.newsletter,
            }));
        }
        if (e.target.name === "agreeTerms") {
            setInfo((prevInfo) => ({
                ...prevInfo,
                agreeTerms: !prevInfo.agreeTerms,
            }));
        }
    };

    const validateEmail = (emailAdress) => {
        // let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAdress.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    };

    const send = async () => {
        const url =
            process.env.NODE_ENV === "development"
                ? "http://localhost:8000/order"
                : "https://testbs.synvie.de/send/order/";

        try {
            setLoading(true);
            const isValid = info.newsletter
                ? validateEmail(info.orderEmail)
                : true;
            if (isValid && info.agreeTerms) {
                await axios.post(url, info, {
                    withCredentials: true,
                    headers: { "Content-Type": "application/json" },
                });
                navigate("/thank-you-order");

                if (info.agreeTerms) {
                    // alert('Eine Email mit Informationen zur Kontaktaufnahme wurde an die angegebene Emailadresse gesendet.')
                } else {
                    alert(
                        "Bitte stimmen Sie den Datenschutzbestimmungen und Nutzungsbedingungen zu.",
                    );
                }
            } else if (!isValid) {
                alert("Bitte geben Sie eine gültige E-Mail Adresse ein");
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error(err);
            alert("Es ist ein Fehler aufgetreten.");
        }
    };
    return (
        <div className={styles.orderRoot}>
            <div className={styles.sectionTop}>
                <div className={styles.header}>
                    <h2>Treffen Sie Ihre persönliche Auswahl</h2>
                </div>

                <div className={styles.row}>
                    <img src={HeftA} />

                    <div className={styles.textbox}>
                        <h6>Rückenmarkstimulation (SCS)</h6>
                        <p>
                            Erfahren Sie hier, wie die SCS zur individuellen
                            Schmerzlinderung eingesetzt werden kann.
                        </p>
                    </div>
                    <input
                        type="checkbox"
                        name="choiceA"
                        checked={info.choiceA}
                        onChange={handleChange}
                        value={1}
                    />
                </div>
                <div className={styles.row}>
                    <img src={HeftB} />
                    <div className={styles.textbox}>
                        <h6>
                            Broschüre für die Testphase mit einer temporären
                            Rückenmarkstimulation (SCS)
                        </h6>
                        <p>
                            Diese Broschüre erläutert Ihnen die Grundlagen, die
                            Sie für die Testphase mit einem temporären
                            Impulsgeber wissen müssen.
                        </p>
                    </div>
                    <input
                        type="checkbox"
                        name="choiceB"
                        checked={info.choiceB}
                        onChange={handleChange}
                        value={2}
                    />
                </div>
                <div className={styles.row}>
                    <img src={HeftC} />

                    <div className={styles.textbox}>
                        <h6>Radiofrequenz-Ablation </h6>
                        <p>
                            Dauerhafte Schmerzlinderung - ohne Operation oder
                            Medikamente. Dieses schnelle, einfache Verfahren
                            kann eine monatelange Schmerzlinderung verschaffen.
                        </p>
                    </div>
                    <input
                        type="checkbox"
                        name="choiceC"
                        checked={info.choiceC}
                        onChange={handleChange}
                        value={3}
                    />
                </div>
            </div>
            <div className={styles.sectionBottom}>
                <div className={styles.half}>
                    <div className={styles.form}>
                        <h3>
                            Bitte füllen Sie die nachfolgenden Felder
                            vollständig aus.
                        </h3>
                        <input
                            type="text"
                            placeholder="Vorname, Nachname"
                            name="name"
                            value={info.name}
                            className={styles.input}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder="Straße, Hausnummer"
                            name="street"
                            value={info.street}
                            className={styles.input}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder="PLZ, Ort"
                            name="location"
                            value={info.location}
                            className={styles.input}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder="E-Mail"
                            name="orderEmail"
                            value={info.orderEmail}
                            className={styles.input}
                            onChange={handleChange}
                        />

                        <input
                            type="text"
                            placeholder="Telefon"
                            name="phone"
                            value={info.phone}
                            className={styles.input}
                            onChange={handleChange}
                        />

                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                name="newsletter"
                                value={info.newsletter}
                                checked={info.newsletter}
                                className={styles.checkboxInput}
                                onChange={handleChange}
                            />

                            <p>
                                Ich möchte weitere Informationen und / oder
                                Benachrichtigungen über Veranstaltungen in
                                meiner Region erhalten. HINWEIS: Wenn Sie dieses
                                Kästchen ankreuzen, erhalten Sie weitere
                                Informationen über chronische Schmerzen. Ihre
                                Quiz-Antworten und Ihre E-Mail-Adresse werden
                                nur von der Synvie GmbH, einer Agentur für
                                Schmerzspezialisten, die im Auftrag von Boston
                                Scientific arbeitet, verarbeitet.
                            </p>
                        </div>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                name="agreeTerms"
                                value={info.agreeTerms}
                                checked={info.agreeTerms}
                                className={styles.checkboxInput}
                                onChange={handleChange}
                            />

                            <p>
                                Bitte stimmen Sie mit einem Klick den{" "}
                                <a href="/privacy-policy" target="_blank">
                                    Datenschutz
                                </a>
                                - und{" "}
                                <a href="/terms-of-service" target="_blank">
                                    {" "}
                                    Nutzungsbedingungen
                                </a>{" "}
                                der Synvie GmbH zu.
                            </p>
                        </div>
                    </div>
                </div>

                <div className={styles.half}>
                    <button
                        onClick={send}
                        disabled={loading}
                        className={
                            !loading
                                ? styles.button__mobile
                                : styles.mobile__disabled
                        }
                    >
                        Senden
                    </button>
                    <div className={styles.contactContent}>
                        <p className={styles.contact}>Bleiben Sie in Kontakt</p>
                        <img src={PhoneIcon} alt="contact" />
                        <div className={styles.info}>
                            <p>Schmerz-Spezialisten</p>
                            <p>Mo - Fr 10 bis 16 Uhr</p>
                            <p>Kostenfreie Rufnummer</p>
                            <p>
                                <a href="tel:0800 500 44 111">
                                    Tel.: 0800 500 44 111{" "}
                                </a>
                            </p>
                            <p>
                                <a
                                    href="mailto:kontakt@schmerz-experts.de"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    kontakt@schmerz-experts.de
                                </a>
                            </p>
                        </div>
                    </div>
                    <button
                        onClick={send}
                        disabled={loading}
                        className={!loading ? styles.button : styles.disabled}
                    >
                        Senden
                    </button>
                </div>
            </div>
        </div>
    );
}
