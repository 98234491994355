import { Box, TextField, Typography } from "@mui/material";

import { AgreeTermsSection } from "./AgreeTermsSection";
import { TextFieldWithError } from "../components/TextFieldWithError";
import { SummarySection } from "./SummarySection";
export default function ContactViaPhoneForm({
    fullName,
    fullNameRef,
    email,
    emailConfirm,
    phone,
    note,
    agreeTerms,
    agreeNewsletter,
    agreeResults,
    handleChange,
    handleSubmit,
    invalidFields,
    loading,
    setInvalidFields,
    contactOption,
    selectedDate,
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedTimeSlot,
    quizCompleted,
}) {
    const styles = {
        formField: {
            flex: 1,
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
        formRow: {
            flex: 1,
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
        },

        checkboxRow: {
            display: "flex",
            alignItems: "flex-start",
        },
    };
    return (
        <Box sx={styles.formContainer}>
            <Box sx={styles.formRow}>
                <TextFieldWithError
                    value={fullName}
                    inputRef={fullNameRef}
                    handleChange={handleChange}
                    name="fullName"
                    label="Vorname, Nachname*"
                    size="small"
                    errorMsg={
                        invalidFields.includes("fullName") &&
                        "Bitte geben Sie Ihren vollständigen Namen an.\nVerwenden Sie ausschließlich Buchstaben."
                    }
                />
                <TextFieldWithError
                    value={phone}
                    handleChange={handleChange}
                    name="phone"
                    label="Telefon*"
                    size="small"
                    errorMsg={
                        invalidFields.includes("phone") &&
                        "Bitte geben Sie Ihre Telefonnummer an.\nVerwenden Sie ausschließlich Ziffern."
                    }
                />
                <TextFieldWithError
                    value={email}
                    handleChange={handleChange}
                    name="email"
                    label="E-Mail*"
                    size="small"
                    errorMsg={
                        invalidFields.includes("email") &&
                        "Bitte geben Sie eine gültige E-Mail-Adresse an."
                    }
                />
            </Box>
            <Box sx={styles.formRow}>
                <TextFieldWithError
                    sx={styles.formField}
                    value={note}
                    handleChange={handleChange}
                    name="note"
                    label="Notiz*"
                    multiline
                    rows={2.5}
                    errorMsg={
                        invalidFields.includes("note") &&
                        "Bitte geben Sie einen Grund für die Kontaktanfrage an."
                    }
                />
            </Box>
            <SummarySection
                contactOption={contactOption}
                selectedDate={selectedDate}
                selectedDay={selectedDay}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                selectedTimeSlot={selectedTimeSlot}
            />

            <AgreeTermsSection
                handleChange={handleChange}
                agreeNewsletter={agreeNewsletter}
                agreeResults={agreeResults}
                agreeTerms={agreeTerms}
                handleSubmit={handleSubmit}
                loading={loading}
                quizCompleted={quizCompleted}
            />
        </Box>
    );
}
