export const months = [
    { value: 0, label: "Januar" },
    { value: 1, label: "Februar" },
    { value: 2, label: "März" },
    { value: 3, label: "April" },
    { value: 4, label: "Mai" },
    { value: 5, label: "Juni" },
    { value: 6, label: "Juli" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "Oktober" },
    { value: 10, label: "November" },
    { value: 11, label: "Dezember" },
];
export const weekdays = {
    1: "Montag",
    2: "Dienstag",
    3: "Mittwoch",
    4: "Donnerstag",
    5: "Freitag",
    6: "Samstag",
    0: "Sonntag",
};

export const weekdaysArray = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

export const timeSlotColumnsDiMiDo = [
    ["10:00", "10:30", "11:00", "11:30", "12:00", "12:30"],
    ["13:00", "13:30", "14:00", "14:30", "15:00", "15:30"],
    ["16:00", "16:30", "17:00", "17:30", "18:00"],
];

export const timeSlotColumnsMoFr = [
    ["10:00", "10:30", "11:00", "11:30"],
    ["12:00", "12:30", "13:00", "13:30"],
    ["14:00", "14:30", "15:00", "15:30"],
];

export const formatDate = (day, date, month, year) => {
    const formattedDay = `${weekdays[day]}`;
    const formattedDate = date < 10 ? `0${date}` : date;
    const formattedDateString = `${formattedDay}, ${formattedDate}`;
    const formattedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
    return `${formattedDateString}.${formattedMonth}.${year}`;
};
export const formatDateWithoutWeekday = (day, date, month, year) => {
    const formattedDate = date < 10 ? `0${date}` : date;
    const formattedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
    return `${formattedDate}.${formattedMonth}.${year}`;
};

export const formatDateObject = (myDate) => {
    if (!myDate) return;
    const date = myDate.getDate();
    const formattedDate = date < 10 ? `0${date}` : date;
    const month = myDate.getMonth();
    const formattedMonth = month + 1 < 10 ? `0${month + 1}` : month + 1;
    return `${formattedDate}.${formattedMonth}.${myDate.getFullYear()}`;
};

export const getAllDaysInMonth = (month, year) => {
    const startOfMonth = new Date(year, month);
    const endOfMonth = new Date(year, month + 1, 0);
    let day = startOfMonth;
    let daysInMonth = [];

    while (day <= endOfMonth) {
        daysInMonth.push(new Date(day));
        day.setDate(day.getDate() + 1);
    }

    return daysInMonth;
};

export const splitDaysToWeeks = (days) => {
    let weeks = [];
    let week = [];
    const firstDay = days[0].getDay();
    const firstWeek = firstDay !== 0 ? new Array(firstDay - 1).fill(null) : [];
    firstWeek.push(...days.slice(0, 7 - (firstDay - 1)));
    const remainingDays = days.slice(7 - (firstDay - 1));
    weeks.push(firstWeek);
    remainingDays.forEach((day, index) => {
        if (index % 7 === 0 && index !== 0) {
            weeks.push(week);
            week = [];
        }
        if (index === remainingDays.length - 1) {
            week.push(day);
            const remainingDays = 7 - week.length;
            week.push(...new Array(remainingDays).fill(null));
            weeks.push(week);
            return;
        }
        week.push(day);
    });
    return weeks;
};

export const isWeekend = (day) => {
    if (!day) return false;
    if (day.getDay() === 0 || day.getDay() === 6) {
        return true;
    }
};

export const isInPast = (day) => {
    if (!day) return false;

    const today = new Date();
    const cetOffset = -60; // CET is UTC+1
    const localOffset = today.getTimezoneOffset();
    const offsetDifference = cetOffset - localOffset;

    const adjustedToday = new Date(
        today.setMinutes(today.getMinutes() + offsetDifference),
    );
    adjustedToday.setHours(0, 0, 0, 0);

    const adjustedDay = new Date(
        new Date(day).setMinutes(new Date(day).getMinutes() + offsetDifference),
    );
    adjustedDay.setHours(0, 0, 0, 0);

    return adjustedDay <= adjustedToday;
};

export const parseDateForBackend = (
    selectedDate,
    selectedMonth,
    selectedYear,
) => {
    const parsedMonth =
        selectedMonth + 1 < 10 ? `0${selectedMonth + 1}` : selectedMonth + 1;
    const parsedDate = selectedDate < 10 ? `0${selectedDate}` : selectedDate;
    return `${selectedYear}-${parsedMonth}-${parsedDate}`;
};

export const formatTimeSlot = (timeslot) => {
    const parts = timeslot.split(":");
    const startsOnFullHour = parts[1] === "00";
    const endTime = startsOnFullHour
        ? `${parts[0]}:30`
        : `${Number(parts[0]) + 1}:00`;
    return `${timeslot} - ${endTime}`;
};

export const isBlacklistedDay = (day, occupiedDays) => {
    if (!day || !occupiedDays) return false;
    const formattedDay = formatDateObject(day);
    return occupiedDays.includes(formattedDay);
};

export const getNextAvailableDate = (occupiedDays) => {
    let daysToAdd;

    if (isBlacklistedDay(new Date(), occupiedDays)) {
        daysToAdd = 1;
    }

    const today = new Date();
    const dayOfWeek = today.getDay();
    if (dayOfWeek === 6) {
        // If it's Saturday
        daysToAdd = 2;
    } else if (dayOfWeek === 0) {
        // If it's Sunday
        daysToAdd = 1;
    } else if (dayOfWeek === 5) {
        // If it's Friday
        daysToAdd = 3;
    } else {
        daysToAdd = 1;
    }
    today.setDate(today.getDate() + daysToAdd);

    return [today.getDate(), today.getDay()];
};

export const showTimeSlots = (
    selectedDay,
    selectedDate,
    selectedMonth,
    selectedYear,
    occupiedDays,
) => {
    if (
        occupiedDays.includes(
            formatDateWithoutWeekday(
                selectedDay,
                selectedDate,
                selectedMonth,
                selectedYear,
            ),
        )
    )
        return false;
    if (isInPast(new Date(selectedYear, selectedMonth, selectedDate)))
        return false;
    return true;
};
