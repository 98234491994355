import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { contactStyles as styles } from "./ContactNewStyles";

import InfoIcon from "./../assets/pain_contact/info.png";

import { DateTimePicker } from "../components/DateTimePicker";
import ContactViaEmailForm from "./ContactViaEmailForm";
import ContactViaPhoneForm from "./ContactViaPhoneForm";
import { validateEmailForm, validatePhoneForm } from "../utils/validateUtils";
import {
    getOccupiedDaysAPI,
    getOccupiedTimeSlotsAPI,
    submitContactViaEmailFormAPI,
    submitContactViaPhoneFormAPI,
} from "../api/contact.api";
import {
    getNextAvailableDate,
    isBlacklistedDay,
    isInPast,
    isWeekend,
    parseDateForBackend,
} from "../utils/dateTimePickerUtils";
import { useQuiz } from "../contexts/QuizContext";
import { useNavigate } from "react-router-dom";
export default function ContactNew() {
    const inputFullNameRef = React.useRef();
    const initRender = React.useRef(true);
    const navigate = useNavigate();
    const {
        setCurrentQuestion,
        questionOne,
        questionTwo,
        questionThree,
        questionFour,
        questionFive,
        questionSix,
        questionSeven,
        quizCompleted,
        setQuizCompleted,
    } = useQuiz();

    const contactPhoneNumber = "080050044111";
    const [loading, setLoading] = useState(false);
    const [loadingTimes, setLoadingTimes] = useState(false);
    const [contactOption, setContactOption] = useState("scheduleCall");
    const [occupiedTimeSlots, setOccupiedTimeSlots] = useState([]);

    const [occupiedDays, setOccupiedDays] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedDate, setSelectedDate] = useState(
        getNextAvailableDate(occupiedDays)[0],
    );
    const [selectedDay, setSelectedDay] = useState(
        getNextAvailableDate(occupiedDays)[1],
    );
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");
    const [phone, setPhone] = useState("");
    const [note, setNote] = useState("");
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreeResults, setAgreeResults] = useState(true);
    const [agreeNewsletter, setAgreeNewsletter] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const nextMonth = () => {
        if (selectedMonth === 11) {
            setSelectedYear((prev) => prev + 1);
            setSelectedMonth(0);
            return;
        }
        setSelectedMonth((prev) => prev + 1);
        setSelectedDate(1);
    };
    const prevMonth = () => {
        if (selectedMonth === 0) {
            setSelectedYear((prev) => prev - 1);
            setSelectedMonth(11);
            return;
        }
        setSelectedMonth((prev) => prev - 1);
        setSelectedDate(1);
    };

    const formatPhoneNumber = (phoneNumber) => {
        return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
            4,
            7,
        )} ${phoneNumber.slice(7, 9)} ${phoneNumber.slice(9)}`;
    };

    const selectContactOption = (option) => {
        setContactOption(option);
        initRender.current = false;

        setAgreeTerms(false);
        if (option === "immediateCall") {
            window.location.href = `tel:${contactPhoneNumber}`;
        }
    };

    useEffect(() => {
        if (
            (contactOption === "scheduleCall" ||
                contactOption === "sendEmail") &&
            !initRender.current
        ) {
            if (inputFullNameRef.current)
                inputFullNameRef.current.focus({ preventScroll: true });
            if (inputFullNameRef.current)
                inputFullNameRef.current.select({ preventScroll: true });
        }
    }, [contactOption]);

    const handleDateSelect = (day) => {
        if (!day) return;
        setSelectedDate(day.getDate());
        setSelectedDay(day.getDay());
        setSelectedTimeSlot("");
    };
    const handleTimeSelect = (time) => {
        setInvalidFields((prev) =>
            prev.filter((field) => field !== "selectedTimeSlot"),
        );
        setSelectedTimeSlot(time);
    };

    const handleChange = (e) => {
        setInvalidFields((prev) =>
            prev.filter((field) => field !== e.target.name),
        );
        if (e.target.name === "fullName") setFullName(e.target.value);
        if (e.target.name === "email") setEmail(e.target.value);
        if (e.target.name === "emailConfirm") setEmailConfirm(e.target.value);
        if (e.target.name === "phone") setPhone(e.target.value);
        if (e.target.name === "note") setNote(e.target.value);
        if (e.target.name === "agreeTerms") setAgreeTerms(e.target.checked);
        if (e.target.name === "agreeResults") setAgreeResults(e.target.checked);
        if (e.target.name === "agreeNewsletter")
            setAgreeNewsletter(e.target.checked);
    };

    const phoneFormIsValid = () => {
        const invalid = validatePhoneForm(
            fullName,
            email,
            phone,
            selectedDate,
            selectedTimeSlot,
            note,
        );
        if (invalid.length === 0) return true;
        setInvalidFields(invalid);
        return false;
    };
    const emailFormIsValid = () => {
        const invalid = validateEmailForm(
            fullName,
            email,
            emailConfirm,
            phone,
            note,
            selectedDate,
            selectedTimeSlot,
        );
        if (invalid.length === 0) return true;
        setInvalidFields(invalid);
        return false;
    };

    const handleSubmit = async () => {
        if (contactOption === "scheduleCall" && phoneFormIsValid()) {
            setLoading(true);
            try {
                const res = await submitContactViaPhoneFormAPI({
                    fullName,
                    email,
                    phone,
                    note,
                    date: parseDateForBackend(
                        selectedDate,
                        selectedMonth,
                        selectedYear,
                    ),
                    timeSlot: selectedTimeSlot,
                    newsletter: agreeNewsletter,
                    requestedResults: agreeResults,
                    questionnaire: quizCompleted
                        ? {
                              questionOne,
                              questionTwo,
                              questionThree,
                              questionFour,
                              questionFive,
                              questionSix,
                              questionSeven,
                          }
                        : null,
                });
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                navigate("/thank-you");
            }
            return;
        }
        if (contactOption === "sendEmail" && emailFormIsValid()) {
            setLoading(true);
            try {
                const res = await submitContactViaEmailFormAPI({
                    fullName,
                    email,
                    phone,
                    note,
                    date: null,
                    timeSlot: null,
                    newsletter: agreeNewsletter,
                    requestedResults: agreeResults,
                    questionnaire: quizCompleted
                        ? {
                              questionOne,
                              questionTwo,
                              questionThree,
                              questionFour,
                              questionFive,
                              questionSix,
                              questionSeven,
                          }
                        : null,
                });
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
                navigate("/thank-you");
            }
            return;
        }
    };
    useEffect(() => {
        const getOccupiedDays = async () => {
            try {
                setLoadingTimes(true);
                const response = await getOccupiedDaysAPI();
                setOccupiedDays(response.detail || []);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingTimes(false);
            }
        };
        const getOccupiedTimeSlots = async () => {
            if (contactOption !== "scheduleCall") return;
            try {
                setLoadingTimes(true);
                const response = await getOccupiedTimeSlotsAPI({
                    selectedDate,
                    selectedMonth,
                    selectedYear,
                });
                setOccupiedTimeSlots(response.detail || []);
            } catch (error) {
                console.error(error);
            } finally {
                setTimeout(() => {
                    setLoadingTimes(false);
                }, 300);
            }
        };
        getOccupiedTimeSlots();
        getOccupiedDays();
    }, [contactOption, selectedDate, selectedMonth, selectedYear]);
    return (
        <Box sx={styles.container}>
            <Box sx={styles.titleContainer}>
                <img src={InfoIcon} alt="info" />
                {quizCompleted ? (
                    <Typography sx={styles.title}>
                        Aufgrund Ihrer Antworten könnten Sie ein Kandidat / eine
                        Kandidatin für eine Rückenmarkstimulation (SCS) oder
                        Radiofrequenzablation (RFA) sein, aber nur ein Arzt /
                        eine Ärztin kann diese Entscheidung treffen. *
                    </Typography>
                ) : (
                    <Typography sx={styles.title}>
                        Ich möchte mehr über die Therapie der<br></br>{" "}
                        Rückenmarkstimulation erfahren.
                    </Typography>
                )}
            </Box>
            {/*
            {quizCompleted && (
                <Button
                    sx={{ ...styles.contactOptionButton, mb: "1rem" }}
                    onClick={() => {
                        setCurrentQuestion(7);
                        setQuizCompleted(false);
                    }}
                >
                    Zurück zum Quiz
                </Button>
                )} */}
            {/* <Typography>
                Sie haben Fragen zur Rückenmarkstimulation? Wir beraten Sie
                <strong> kostenfrei</strong> und <strong>unverbindlich</strong>{" "}
                zu dieser Therapie und unterstützen Sie auf jedem Schritt Ihres
                Weges in ein schmerzfreies Leben.
                <strong>
                    &nbsp;Wir sind Montag bis Freitag von 10 bis 18 Uhr für Sie
                    erreichbar.
                </strong>
            </Typography> */}
            <Typography sx={styles.subtitle}>
                Wie möchten Sie mit uns Kontakt aufnehmen?
            </Typography>
            <Box sx={styles.buttonBox}>
                <Button
                    sx={
                        contactOption === "immediateCall"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("immediateCall")}
                >
                    Direkt anrufen ({formatPhoneNumber(contactPhoneNumber)})
                </Button>

                <Button
                    sx={
                        contactOption === "scheduleCall"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("scheduleCall")}
                >
                    Telefontermin vereinbaren
                </Button>
                <Button
                    sx={
                        contactOption === "sendEmail"
                            ? styles.contactOptionButtonSelected
                            : styles.contactOptionButton
                    }
                    onClick={() => selectContactOption("sendEmail")}
                >
                    E-Mail
                </Button>
            </Box>
            {contactOption === "scheduleCall" && (
                <>
                    <DateTimePicker
                        selectedDate={selectedDate}
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedTimeSlot={selectedTimeSlot}
                        selectedYear={selectedYear}
                        contactOption={contactOption}
                        handleDateSelect={handleDateSelect}
                        handleTimeSelect={handleTimeSelect}
                        invalidFields={invalidFields}
                        occupiedTimeSlots={occupiedTimeSlots}
                        occupiedDays={occupiedDays}
                        loadingTimes={loadingTimes}
                        nextMonth={nextMonth}
                        prevMonth={prevMonth}
                    />
                    <Typography sx={styles.calendarTitle}>
                        Geben Sie bitte an, wie wir Sie erreichen können.
                    </Typography>
                    <ContactViaPhoneForm
                        email={email}
                        phone={phone}
                        fullName={fullName}
                        agreeNewsletter={agreeNewsletter}
                        agreeTerms={agreeTerms}
                        agreeResults={agreeResults}
                        handleChange={handleChange}
                        note={note}
                        handleSubmit={handleSubmit}
                        invalidFields={invalidFields}
                        setInvalidFields={setInvalidFields}
                        loading={loading}
                        contactOption={contactOption}
                        selectedDate={selectedDate}
                        selectedDay={selectedDay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        selectedTimeSlot={selectedTimeSlot}
                        quizCompleted={quizCompleted}
                        fullNameRef={inputFullNameRef}
                    />
                </>
            )}
            {contactOption === "sendEmail" && (
                <ContactViaEmailForm
                    fullName={fullName}
                    email={email}
                    emailConfirm={emailConfirm}
                    phone={phone}
                    note={note}
                    agreeNewsletter={agreeNewsletter}
                    agreeTerms={agreeTerms}
                    agreeResults={agreeResults}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    invalidFields={invalidFields}
                    setInvalidFields={setInvalidFields}
                    loading={loading}
                    contactOption={contactOption}
                    quizCompleted={quizCompleted}
                    fullNameRef={inputFullNameRef}
                />
            )}

            <Typography sx={{ mt: "1rem" }} variant="caption">
                *Der Inhalt dieses Schmerz-Test dient nur zu Informationszwecken
                und ist nicht für Produktwerbung oder medizinische Diagnosen
                bestimmt. Die darin enthaltenen Informationen stellen keine
                medizinische Beratung da. Die Synvie GmbH übernimmt keine
                Haftung für die Vollständigkeit, Richtigkeit oder Aktualität
                dieser Informationen. Die Synvie GmbH empfiehlt, dass Sie sich
                mit all Ihren Fragen rund um Ihre Gesundheit an
                Ihren Arzt wenden.
            </Typography>
        </Box>
    );
}
